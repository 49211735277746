import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import GradientButton from "../../components/ui/GradientButton";

function StorySection() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box component="section" sx={{ padding: "3rem 0rem" }}>
        {/* title */}
        <Box component="header" sx={{ marginBottom: "3rem" }}>
          <Typography variant="h2" fontFamily={"ITC"}>
            {t("landingPage.story.hasStory")}
          </Typography>
          <Typography variant="h2" fontFamily={"ITC"}>
            {t("landingPage.story.weDesign")}
          </Typography>
          <Typography variant="h2" fontFamily={"ITC"}>
            {t("landingPage.story.toTellIt")}
          </Typography>
        </Box>
        {/* paragraphs */}
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { xs: "3rem", md: "5rem" }, justifyContent: "space-between", marginBottom: "2rem" }}>
          <Box sx={{ maxWidth: "850px" }}>
            <Typography align="justify" variant="h5" fontFamily={"ITC"}>
              {t("landingPage.story.paragraph1")}
            </Typography>
          </Box>
          <Box sx={{ maxWidth: "850px" }}>
            <Typography align="justify" variant="h5" fontFamily={"ITC"}>
              {t("landingPage.story.paragraph2")}
            </Typography>
          </Box>
        </Box>
        {/* button */}
        <GradientButton>
          <Typography fontWeight={500} variant="body1" onClick={() => navigate("/services")}>
            {t("landingPage.story.button")}
          </Typography>
        </GradientButton>
      </Box>
    </>
  );
}

export default StorySection;
