import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function MiniTitle({ name }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.5rem", marginBottom: "0.5rem" }}>
      <Box sx={{ width: "15px", height: "2px", backgroundColor: "gray" }} />
      <Box>
        <Typography fontFamily={"ITC"} sx={{ color: "gray", fontSize: "0.875rem" }}>
          {t(name)}
        </Typography>
      </Box>
    </Box>
  );
}

export default MiniTitle;
