import React from "react";
import PageContainer from "../../components/ui/PageContainer";
import Footer from "../../components/ui/Footer";
import { Container } from "@mui/material";

import ArticleSection from "./ArticleSection";
import SimilarArticlesSection from "./SimilarArticlesSection";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

function BlogArticlePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log("id article", id);

  const [articleData, setArticleData] = useState();
  const [articlesData, setArticlesData] = useState();
  const [similarTags, setSimilarTags] = useState();

  //language
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  console.log("CURRENT LANG", currentLanguage);

  useEffect(() => {
    Api.get(`${Urls.GET_ARTCIEL_BY_ID}/${id}`, {
      headers: { "Content-Type": "application/json", "accept-language": `${currentLanguage}` },
    })
      .then((response) => {
        setArticleData(response.data.data.post);
        setSimilarTags(response.data.data.post.tags);
	setArticlesData(response.data.data?.similar);
    })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate("/not-found");
        }
      });
  }, [currentLanguage, window.location.pathname]);

  //get list articles
/*  useEffect(() => {
    Api.get(Urls.GET_ARTICLES + "?perPage=3", {
      headers: { "Content-Type": "application/json", "accept-language": `${currentLanguage}` },
      params: { tags: similarTags?.map((tag) => tag.id) },
    }).then((response) => {
      setArticlesData(response.data.posts);
      console.log("similar tags", similarTags);
    });
  }, [currentLanguage, similarTags, window.location.pathname]);
*/
  return (
    <>
      <Container maxWidth="xl">
        <ArticleSection article={articleData} />
      </Container>

      <Container maxWidth="xl">
        <SimilarArticlesSection articles={articlesData} />
      </Container>

      <Footer />
    </>
  );
}

export default BlogArticlePage;
