import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import MiniTitle from "../../components/ui/MiniTitle.js";
import Api from "../../api/Api.js";
import { Urls } from "../../api/Urls.js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BlogTag from "../../components/ui/BlogTag/index.js";

function ArticleSection({ article }) {
  return (
    <Box component="section" sx={{ paddingTop: "3rem" }}>
      <Box sx={{ marginBottom: "3rem" }}>
        <Grid container spacing={0}>
          <Grid item md={6}>
            <Box sx={{ marginBottom: "1rem" }}>
              <MiniTitle name="Blog" />
              <Box sx={{ padding: "1rem 0rem" }}>
                <Typography component="h1" variant="h4" fontFamily={"ITC"}>
                  {article?.title}
                </Typography>
              </Box>
              <Box sx={{ padding: "1rem 0rem" }}>
                {article?.tags.map((t, index) => (
                  <BlogTag key={index} title={t.name} />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box sx={{ marginBottom: "3rem", width: "100%" }}>
              <img width="100%" height="auto" style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", borderRadius: "0.7rem", aspectRatio: "16/5" }} src={article?.image_url} alt="" />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box sx={{ padding: "1rem 0rem", fontSize: "1.3rem", fontFamily: "ITC", lineHeight: "2rem" }} dangerouslySetInnerHTML={{ __html: article?.text }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ArticleSection;
