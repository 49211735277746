import "./App.css";
// import Navbar from "./components/ui/Navbar";
import { useState, useLayoutEffect, useEffect } from "react";
import { Routes, Route, useMatch } from "react-router-dom";
import SharedLayout from "./pages/SharedLayout/SharedLayout";
import HomePage from "./pages/Home/HomePage";
import ServicesPage from "./pages/Services/ServicesPage";
import TechnologiesPage from "./pages/technologies/TechnologiesPage";
import AgencyPage from "./pages/agency/AgencyPage";
import ContactPage from "./pages/contact/ContactPage";
import BlogPage from "./pages/blog/BlogPage";
import BlogArticlePage from "./pages/blogArticle/BlogArticlePage";
import NotFound from "./pages/notFound/NotFound";

function App() {
  const [isBlack, setIsBlack] = useState(false);
  const match = useMatch("/services");

  useEffect(() => {
    if (match) {
      setIsBlack(true);
    } else {
      setIsBlack(false);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<SharedLayout isBlack={isBlack} />}>
          <Route index element={<HomePage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="technologies" element={<TechnologiesPage />} />
          <Route path="agency" element={<AgencyPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="blog">
            <Route index path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<BlogArticlePage />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
