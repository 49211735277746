export const Urls = {
  //get tags for select
  GET_TAGS: "/tags",

  //get article by id
  GET_ARTCIEL_BY_ID: "/posts",

  //get articles
  GET_ARTICLES: "/posts",

  //send contact form
  SEND_EMAIL: "/send-email-contact",
};
