import React from "react";
import { Box, Typography, Container } from "@mui/material";
import PageContainer from "../../components/ui/PageContainer";
import map from "../../imgs/map_final.svg";
import { useTranslation } from "react-i18next";

function FindUsSection() {
  //   const paris = document.getElementById("#PARIS_LOCATION");
  //   const alger = document.getElementById("ALGER_LOCATION");
  //   console.log(paris);

  const { t } = useTranslation();

  return (
    <>
      <Box component="section" sx={{ zIndex: "0", overflow: "hidden", padding: "3rem 0rem" }}>
        <Container maxWidth="xl">
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center", justifyContent: "space-between" }}>
            {/* infos */}
            <Box sx={{ display: "flex", flexDirection: "column", flex: "1.5" }}>
              <Box sx={{ zIndex: "1", paddingBottom: "3rem" }}>
                <Typography color="white" component="h1" variant="h2" fontFamily={"ITC"}>
                  {t("contactPage.title")}
                </Typography>
              </Box>
              <Box sx={{ zIndex: "1", paddingBottom: "8rem", fontSize: { xs: "3.7rem", sm: "5rem", md: "5rem", lg: "5.5rem", xl: "7rem", maxWidth: "650px" } }}>
                <Typography color="white" component="p" variant="h6" fontFamily={"ITC"}>
                  {t("contactPage.paragraph")}
                </Typography>
              </Box>
              <Box>
                <Box sx={{ zIndex: "1", paddingBottom: "1rem", fontSize: { xs: "3.7rem", sm: "5rem", md: "5rem", lg: "5.5rem", xl: "7rem", maxWidth: "650px" } }}>
                  <Typography color="white" component="p" variant="body2" fontFamily={"ITC"}>
                    {t("contactPage.country1")} :
                  </Typography>
                  <Typography color="gray" component="p" variant="body2" fontFamily={"ITC"}>
                    {t("contactPage.adress1")}
                  </Typography>
                  <Typography color="gray" component="p" variant="body2" fontFamily={"ITC"}>
                    {t("contactPage.phone")} : {t("contactPage.phone1")}
                  </Typography>
                </Box>
                <Box sx={{ zIndex: "1", paddingBottom: "1rem", fontSize: { xs: "3.7rem", sm: "5rem", md: "5rem", lg: "5.5rem", xl: "7rem", maxWidth: "650px" } }}>
                  <Typography color="white" component="p" variant="body2" fontFamily={"ITC"}>
                    {t("contactPage.country2")} :
                  </Typography>
                  <Typography color="gray" component="p" variant="body2" fontFamily={"ITC"}>
                    {t("contactPage.adress2")}
                  </Typography>
                  <Typography color="gray" component="p" variant="body2" fontFamily={"ITC"}>
                    {t("contactPage.phone")} : {t("contactPage.phone2")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* map */}
            <Box sx={{ flex: "1" }}>
              <img src={map} width="500px" alt="" />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default FindUsSection;
