import React from "react";
import { Box, Typography } from "@mui/material";
import NotFoundLogo from "../../imgs/NotFound.png";
import { useTranslation } from "react-i18next";
function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      <Box component="section" sx={{ padding: "3rem 0rem" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", justifyContent: "center" }}>
          <img style={{ height: "auto", maxWidth: "80%" }} src={NotFoundLogo} alt="not-found logo" />
          <Typography variant="h3" fontFamily={"ITC"}>
            {t("NOTFOUND.texte")}
          </Typography>
        </div>
      </Box>
    </>
  );
}

export default NotFound;
