import React from "react";
import { Chip } from "@mui/material";

function BlogTag({ title, ...props }) {
  return (
    <Chip
      {...props}
      size="small"
      label={title}
      variant="filled"
      sx={{
        fontFamily: "Poppins",
        fontWeight: "500",
        border: "2px solid black",
        backgroundColor: `white`,
        "& .MuiChip-label": {
          color: "black",
        },
        marginRight: "0.5rem",
      }}
    />
  );
}

export default BlogTag;
