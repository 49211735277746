import React from "react";
import HeroSection from "./HeroSection";
import ProjectsSection from "./ProjectsSection";
import StorySection from "./StorySection";
import ClientsSection from "./ClientsSection";
import PageContainer from "../../components/ui/PageContainer";
import TeamSection from "./TeamSection";
import BlogSection from "./BlogSection";
import ActionSection from "./ActionSection";
import Footer from "../../components/ui/Footer";
import { Container } from "@mui/material";

function HomePage() {
  return (
    <>
      <Container maxWidth="xl">
        <HeroSection />
      </Container>

      <Container maxWidth="xl">
        <StorySection />
      </Container>

      <Container maxWidth="xl">
        <ProjectsSection />
      </Container>

      <ClientsSection />

      <Container maxWidth="xl">
        <BlogSection />
      </Container>

      <Container maxWidth="xl">
        <ActionSection />
      </Container>

      <Footer />
    </>
  );
}

export default HomePage;
