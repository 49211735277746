import React from "react";
import { Box, Typography, TextField, Container } from "@mui/material";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import contactBlur from "../../imgs/blurs/contactBlur.jpg";
import PageContainer from "../../components/ui/PageContainer";
import MiniTitle from "../../components/ui/MiniTitle.js";
import { styled } from "@mui/system";
import FormikErrorText from "../../components/ui/FormikErrorText";
import FormikLabel from "../../components/ui/FormikLabel";
import GradientButton from "../../components/ui/GradientButton";
import { useTranslation } from "react-i18next";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useRef, useState } from "react";
import AlertSuccess from "../../components/ui/AlertSuccess";

function ContactSection() {
  const GlassBox = styled(Box)({
    background: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(10px)",
    borderRadius: "20px",
    padding: "20px",
    //   boxShadow: "0 8px 32px 0 rgba(61, 130, 187, 0.171)",
    border: "1px solid rgb(136, 136, 136)",
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    message: "",
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    email: yup.string().email("Email must be valid").required("This field is required"),
    company: yup.string().required("This field is required"),
    message: yup.string().max(256, "Exceeded 256 caracters").required("This field is required"),
  });

  const formRef = useRef();
  const [isSent, setIsSent] = useState(false);
  const [isNotSent, setIsNotSent] = useState(false);

  const onSubmitSend = (values) => {
    Api.post(
      Urls.SEND_EMAIL,
      { first_name: values.firstName, last_name: values.lastName, email: values.email, company: values.company, message: values.message },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        console.log(response);
        console.log(formRef.current);
        formRef.current.resetForm();
        setIsSent(true);
        setIsNotSent(false);
      })
      .catch((e) => {
        setIsNotSent(true);
        setIsSent(false);
      });
  };

  const { t } = useTranslation();

  return (
    <>
      <Box component="section" sx={{ position: "relative", zIndex: "0", overflow: "hidden", padding: "3rem 0rem 6rem 0rem" }}>
        <Box sx={{ zIndex: "0", position: "absolute", top: { lg: "-40%", xs: "4%" }, right: "0%" }}>
          <img src={contactBlur} />
        </Box>
        <Container maxWidth="xl">
          {/* text */}
          <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, paddingBottom: "5rem" }}>
            <Box sx={{ flex: "1", zIndex: "99", display: "flex", flexDirection: "column", padding: "0rem 2rem 0rem 0rem" }}>
              <MiniTitle name="Contact" />
              <Box sx={{ maxWidth: "750px", zIndex: "1", paddingBottom: "4rem" }}>
                <Typography color="white" component="h1" variant="h2" fontFamily={"ITC"}>
                  {t("contactPage.yes")}
                </Typography>
              </Box>
            </Box>
            {/* Form */}
            <GlassBox sx={{ flex: "1", maxWidth: "600px", padding: "3rem 1.5rem 1rem 1.5rem" }}>
              <Formik innerRef={formRef} onSubmit={(formik) => onSubmitSend(formik)} initialValues={initialValues} validationSchema={validationSchema}>
                {(formik) => (
                  <Form>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, gap: "1.3rem", marginBottom: "1.5rem" }}>
                      <Box sx={{ flex: "1" }}>
                        <FormikLabel sx={{ color: "white", fontFamily: "Poppins", fontSize: "0.875rem" }}>{t("contactPage.form.firstName")}* </FormikLabel>
                        <Field name="firstName" size="small" fullWidth sx={{ backgroundColor: "white", borderRadius: "0.6rem" }} as={TextField} />
                        <ErrorMessage component={FormikErrorText} name="firstName" />
                      </Box>
                      <Box sx={{ flex: "1" }}>
                        <FormikLabel sx={{ color: "white", fontFamily: "Poppins", fontSize: "0.875rem" }}>{t("contactPage.form.lastName")}* </FormikLabel>
                        <Field name="lastName" size="small" fullWidth sx={{ backgroundColor: "white", borderRadius: "0.6rem" }} as={TextField} />
                        <ErrorMessage component={FormikErrorText} name="lastName" />
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, gap: "1.3rem", marginBottom: "1.5rem" }}>
                      <Box sx={{ flex: "1" }}>
                        <FormikLabel sx={{ color: "white", fontFamily: "Poppins", fontSize: "0.875rem" }}>{t("contactPage.form.email")}* </FormikLabel>
                        <Field name="email" size="small" fullWidth sx={{ backgroundColor: "white", borderRadius: "0.6rem" }} as={TextField} />
                        <ErrorMessage component={FormikErrorText} name="email" />
                      </Box>
                      <Box sx={{ flex: "1" }}>
                        <FormikLabel sx={{ color: "white", fontFamily: "Poppins", fontSize: "0.875rem" }}>{t("contactPage.form.company")} </FormikLabel>
                        <Field name="company" size="small" fullWidth sx={{ backgroundColor: "white", borderRadius: "0.6rem" }} as={TextField} />
                        <ErrorMessage component={FormikErrorText} name="company" />
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, gap: "1.3rem" }}>
                      <Box sx={{ flex: "1" }}>
                        <FormikLabel sx={{ color: "white", fontFamily: "Poppins", fontSize: "0.875rem" }}>{t("contactPage.form.message")}* </FormikLabel>
                        <Field multiline rows={7} name="message" size="small" fullWidth sx={{ backgroundColor: "white", borderRadius: "0.6rem" }} as={TextField} />
                        <ErrorMessage component={FormikErrorText} name="message" />
                      </Box>
                    </Box>
                    <Box sx={{ padding: "2rem 0rem" }}>
                      <GradientButton type="submit" sx={{ borderRadius: "5rem", padding: "12px 25px", color: "white" }}>
                        {t("contactPage.form.button")}
                      </GradientButton>
                    </Box>
                  </Form>
                )}
              </Formik>
              {isSent && <AlertSuccess>{"contactPage.form.success"}</AlertSuccess>}
              {isSent && <AlertSuccess>{"contactPage.form.success"}</AlertSuccess>}
            </GlassBox>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default ContactSection;
