import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import MiniTitle from "../../components/ui/MiniTitle.js/index.js";
import PageContainer from "../../components/ui/PageContainer";
import { useState } from "react";
import blurServices from "../../imgs/blurs/blurServices.jpg";
import { useTranslation } from "react-i18next";

function ServicesSection() {
  const { t } = useTranslation();

  const services = ["dev", "hosting", "ui_ux", "consulting"];

  // const services = [
  //   {
  //     name: "UI/UX",
  //     description: "i am ui ux",
  //     subservices: [
  //       { name: "ui_ux", description: "i am user experience" },
  //       { name: "proto", description: "i am prtotyping" },
  //       { name: "acc_design", description: "i am accessibility" },
  //       { name: "design_system", description: "i am design system" },
  //     ],
  //   },
  //   {
  //     name: "Development",
  //     description: " iam hosting",
  //     subservices: [
  //       { name: "web_dev", description: "i am cloud hosting" },
  //       { name: "mob_dev", description: "i am technical support" },
  //     ],
  //   },
  //   {
  //     name: "Hosting",
  //     description: " iam consulting",
  //     subservices: [
  //       { name: "cloud_host", description: "i am st Cloud Hosting" },
  //       { name: "web_migration", description: "i am Website Migration" },
  //       { name: "domain_reg", description: "i am Domain name registration" },
  //     ],
  //   },
  //   {
  //     name: "Consulting",
  //     description: " iam consulting",
  //     subservices: [
  //       { name: "str_con", description: "i am Strategic consulting" },
  //       { name: "op_con", description: "i am Operational consulting" },
  //       { name: "tech_con", description: "i am Technical consulting" },
  //     ],
  //   },
  // ];

  // const [selectedTitle, setSelectedTitle] = useState(t(`servicesPage.services.${services[0].name}`));
  // const [selectedSubtitle, setSelectedSubtitle] = useState(t(`servicesPage.subservices.${services[0].subservices[0].name}`));
  // const [selectedParagraph, setSelectedParagraph] = useState(t(`servicesPage.services.${services[0].name}__description`));

  return (
    <>
      <div>
        <Box component="section" sx={{ zIndex: "0", padding: "3rem 0rem", position: "relative", overflow: "hidden" }}>
          <Box sx={{ zIndex: "0", position: "absolute", top: { xs: "0%", md: "10%" } }}>
            <img src={blurServices} />
          </Box>
          <Container maxWidth="xl">
            <Box sx={{ zIndex: "99", display: "flex", flexDirection: "column" }}>
              <MiniTitle name="Services" />
              <Typography color="white" component="h1" sx={{ zIndex: "1", paddingBottom: "8rem" }} variant="h1" fontFamily={"ITC"}>
                {t("servicesPage.title")}
              </Typography>
            </Box>

            <Grid container className="values" spacing={24}>
              {services.map((s) => (
                <Grid item md={6}>
                  <Box maxWidth="100%" sx={{ paddingTop: "1rem", position: "relative", display: "flex", flexDirection: "column" }}>
                    <Box>
                      <Typography color="white" variant="h3" sx={{ zIndex: 99 }} mb={3}>
                        {t(`servicesPage.services.${s}`)}
                      </Typography>
                      <Typography variant="h6" fontFamily={"ITC"} textAlign="left" color="white">
                        {t(`servicesPage.services.${s}__description`)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            {/*   <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
       
              <Box flex={2} paddingBottom="3rem" display="flex" zIndex="99">
                <Grid container spacing={3}>
                  {services.map((service, index1) => (
                    <Grid key={index1} item md={6} xs={12}>
                      <Box>
                        <Typography fontFamily={"ITC"} variant="h4" color="white" sx={{ zIndex: "1", padding: "0.3rem 0rem" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                              setSelectedTitle(t(`servicesPage.services.${service?.name}`));
                              setSelectedSubtitle(t(`servicesPage.subservices.${service?.subservices[0].name}`));
                            }}>
                            {t(`servicesPage.services.${service?.name}`)}
                          </span>
                        </Typography>
                      </Box>
                      {service.subservices.map((subservice, index2) => (
                        <Box key={index2}>
                          <Typography fontFamily={"ITC"} sx={{ zIndex: "1", padding: "0.3rem 0rem" }} color="white">
                            <span
                              style={{ cursor: "pointer" }}
                              onMouseEnter={() => {
                                setSelectedParagraph(t(`servicesPage.subservices.${subservice?.name}__description`));
                                setSelectedSubtitle(t(`servicesPage.subservices.${subservice?.name}`));
                                setSelectedTitle(t(`servicesPage.services.${service.name}`));
                              }}>
                              {t(`servicesPage.subservices.${subservice?.name}`)}
                            </span>
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Box>
             
              <Box flex={1} display="flex" zIndex="99">
                <Box display="flex" flexDirection="column">
                  <Typography fontFamily={"ITC"} variant="h3" color="white" sx={{ zIndex: "1", padding: "0.3rem 0rem" }}>
                    {selectedTitle}
                  </Typography>
                  <Typography fontFamily={"ITC"} variant="h5" color="white" sx={{ zIndex: "1", padding: "0.7rem 0rem" }}>
                    /{selectedSubtitle}
                  </Typography>
                  <Box>
                    <Typography fontFamily={"ITC"} sx={{ zIndex: "1", padding: "0.3rem 0rem" }} color="white">
                      {selectedParagraph}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
 */}
          </Container>
        </Box>
      </div>
    </>
  );
}

export default ServicesSection;
