import React from "react";
import PageContainer from "../../components/ui/PageContainer";
import HeroSection from "./HeroSection";
import ValuesSection from "./ValuesSection";
import ActionSection from "../Home/ActionSection";
import Footer from "../../components/ui/Footer";
import { Container } from "@mui/material";
import TeamSection from "../Home/TeamSection";

function AgencyPage() {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Container maxWidth="xl">
          <HeroSection />
        </Container>

        <Container maxWidth="xl">
          <ValuesSection />
        </Container>

        <Container sx={{ padding: "8rem 0rem 0rem 0rem" }} maxWidth="xl">
          <TeamSection />
        </Container>

        <Container maxWidth="xl" sx={{ paddingTop: "2rem" }}>
          <ActionSection />
        </Container>

        <Footer />
      </div>
    </>
  );
}

export default AgencyPage;
