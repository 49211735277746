import React from "react";
import TechnologiesSection from "./TechnologiesSection";
import Footer from "../../components/ui/Footer";
import ActionSection from "../Home/ActionSection";
import PageContainer from "../../components/ui/PageContainer";
import { Container } from "@mui/material";

function TechnologiesPage() {
  return (
    <>
      <div>
        <TechnologiesSection />

        <Container maxWidth="xl">
          <ActionSection isBlack={true} />
        </Container>

        <Footer />
      </div>
    </>
  );
}

export default TechnologiesPage;
