import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GradientButton from "../../components/ui/GradientButton";
import { useNavigate } from "react-router-dom";

function ActionSection({ isBlack }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box component="section" sx={{ padding: "3rem 0rem" }}>
        <Typography fontFamily={"ITC"} sx={{ marginBottom: "1rem" }} className={`${isBlack ? "white" : ""}`} variant="h2" component="h3">
          {t("landingPage.action.title")}
        </Typography>
        <GradientButton onClick={() => navigate("/contact")}>
          <Typography variant="body">{t("landingPage.action.button")}</Typography>
        </GradientButton>
      </Box>
    </>
  );
}

export default ActionSection;
