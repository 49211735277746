import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MiniTitle from "../../components/ui/MiniTitle.js";
import BlogTagFilter from "../../components/ui/BlogTagFilter";
import BlogCard from "../../components/ui/BlogCard";
import GradientButton from "../../components/ui/GradientButton";
import Api from "../../api/Api.js";
import { Urls } from "../../api/Urls";
import { useTranslation } from "react-i18next";

function BlogListSection() {
  const [selectedTags, setSelectedTags] = React.useState([]);

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  //navigate
  const navigate = useNavigate();

  //language
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  console.log("CURRENT LANG", currentLanguage);

  //state tags
  const [tags, setTags] = React.useState([]);

  //api data
  const [data, setData] = React.useState();

  //state articles
  const [articles, setArticles] = React.useState([]);

  //get list possible tags
  useEffect(() => {
    Api.get(Urls.GET_TAGS, {
      headers: { "Content-Type": "application/json", "accept-language": `${currentLanguage}` },
    }).then((response) => {
      console.log(response.data.tags);
      setTags(response.data.tags);
    });
  }, [currentLanguage]);

  //get list articles
  useEffect(() => {
    Api.get(Urls.GET_ARTICLES + "?perPage=3", {
      headers: { "Content-Type": "application/json", "accept-language": `${currentLanguage}` },
      params: { tags: selectedTags },
    }).then((response) => {
      console.log(response);
      setArticles(response.data.posts);
      setData(response.data);
    });
  }, [selectedTags, currentLanguage]);

  //handle pagination
  const handlePagination = () => {
    console.log("do i have next page ?", data.links.next);
    if (data.links.next != null) {
      Api.get(data.links.next, { headers: { "Content-Type": "application/json", "accept-language": `${currentLanguage}` } }).then((response) => {
        setData(response.data);
        setArticles(articles.concat(response.data.posts));
      });
    }
  };

  //handle navigate blog
  const handleNavigateBlog = (article_id) => {
    navigate(`/blog/${article_id}`);
  };

  return (
    <>
      <Box component="section" sx={{ paddingTop: "3rem" }}>
        <Box sx={{ paddingBottom: "2rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ paddingBottom: "4rem" }}>
              <MiniTitle name="Blog" />
              <Typography component="h1" sx={{ fontSize: { xs: "3.7rem", sm: "5rem", md: "5rem", lg: "5.5rem", xl: "7rem" } }} variant="h3" fontFamily={"ITC"}>
                {t("blogPage.explore")}
              </Typography>
              <Typography component="h1" sx={{ fontSize: { xs: "3.7rem", sm: "5rem", md: "5rem", lg: "5.5rem", xl: "7rem" } }} variant="h3" fontFamily={"ITC"}>
                {t("blogPage.world")}
              </Typography>
            </Box>
            <Box sx={{ maxWidth: "700px" }}>
              <Typography variant="h6" fontFamily={"ITC"}>
                {t("blogPage.paragraph")}{" "}
              </Typography>
            </Box>
          </Box>

          {tags ? (
            <Box sx={{ display: "flex", flexDirection: "row", padding: "2rem 0rem 0rem 0rem" }}>
              <Typography variant="h6" fontFamily={"ITC"} marginRight="0.5rem">
                {t("blogPage.categories")}
              </Typography>
              <Box>
                {tags.map((t, index) => (
                  <BlogTagFilter key={index} onClick={() => handleTagClick(t.id)} title={t.name} />
                ))}
                {/* <pre>{JSON.stringify(selectedTags)}</pre> */}
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>

        <>
          {articles ? (
            <Box component="section" paddingBottom="4rem">
              <Grid container spacing={4}>
                {articles.map((a, index) => (
                  <Grid key={index} item md={4}>
                    <BlogCard blogTitle={a.title} blogTags={a.tags} blogPicture={a.image_url} navigateMethod={() => handleNavigateBlog(a.id)} />
                  </Grid>
                ))}

                {data?.links?.next && (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3rem 2rem" }}>
                    <GradientButton onClick={handlePagination}>
                      <Typography fontWeight={500} variant="body1">
                        {t("blogPage.loadmore")}
                      </Typography>
                    </GradientButton>
                  </Box>
                )}
              </Grid>
            </Box>
          ) : (
            <Box>
              <Typography variant="h5" fontFamily={"ITC"} marginBottom="4rem">
                {t("blogPage.empty")}
              </Typography>
            </Box>
          )}
        </>
      </Box>
    </>
  );
}

export default BlogListSection;
