import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import BlogCard from "../../components/ui/BlogCard";
import GradientButton from "../../components/ui/GradientButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SimilarArticlesSection({ articles }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  //handle navigate blog
  const handleNavigateBlog = (article_id) => {
    navigate(`/blog/${article_id}`);
  };

  return (
    <>
      <Box component="section" sx={{ paddingTop: "3rem" }}>
        <Box component="header" sx={{ marginBottom: "3rem" }}>
          <Typography variant="h2" fontFamily={"ITC"}>
            {t("similarArticles.similar")}
          </Typography>
        </Box>

        <Box sx={{ marginBottom: "3rem" }}>
          <Grid container spacing={1}>
            {articles?.map((a, index) => (
              <Grid key={index} item md={4}>
                <BlogCard blogTitle={a.title} blogTags={a.tags} blogPicture={a.image_url} navigateMethod={() => handleNavigateBlog(a.id)} />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", padding: "1rem" }}>
            <GradientButton onClick={() => navigate("/blog")}>
              <Typography>{t("similarArticles.button")}</Typography>
            </GradientButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SimilarArticlesSection;
