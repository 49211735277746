import React from "react";
import PageContainer from "../../components/ui/PageContainer";
import Footer from "../../components/ui/Footer";
import BlogListSection from "./BlogListSection";
import { Container } from "@mui/material";

function BlogPage() {
  return (
    <>
      <Container maxWidth="xl">
        <BlogListSection />
      </Container>

      <Footer />
    </>
  );
}

export default BlogPage;
