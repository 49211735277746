import React from "react";
import { useState } from "react";
import { Chip } from "@mui/material";

function BlogTagFilter({ title, onClick, ...props }) {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    onClick(isActive);
  };

  return (
    <Chip
      disableRipple
      {...props}
      onClick={handleClick}
      size="big"
      label={title}
      variant="filled"
      sx={{
        fontFamily: "ITC",
        border: isActive ? "2px solid black" : "2px solid black",
        backgroundColor: isActive ? "black" : "white",
        "& .MuiChip-label": {
          color: isActive ? "white" : "black",
        },
        marginRight: "0.5rem",
        marginBottom: "0.5rem",
        "& .MuiChip-label": {
          color: isActive ? "white" : "black",
        },
        "&:hover": {
          backgroundColor: !isActive ? "rgb(224, 224, 224)" : "rgb(49, 49, 49)",
        },
      }}
    />
  );
}

export default BlogTagFilter;
