import React, { useRef } from "react";
import "./style.scss";
import { useState, useEffect } from "react";
// import logoWhite from "../../../imgs/logo_white.svg";
import logoFull from "../../../imgs/logo_full.svg";
import logoWhite from "../../../imgs/logo_white.svg";
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useMatch } from "react-router-dom";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";

function Navbar({ isBlack }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const checkboxRef = useRef();

  const navigation = [
    { title: "Agency", path: "/agency" },
    { title: "Services", path: "/services" },
    { title: "Technologies", path: "/technologies" },
    { title: "Blog", path: "/blog" },
    { title: "Contact", path: "/contact" },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleMenuClick() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <div className={`${isBlack ? "navbar-wrapper-black" : "navbar-wrapper"}`}>
        <Container maxWidth="xl" sx={{ padding: "1.5rem" }}>
          <div className="navbar-content">
            <div onClick={() => navigate("/")} className="logo-container">
              <img src={isBlack ? logoWhite : logoFull} alt="Digitup logo" />
            </div>

            <nav>
              <ul className="navbar-links">
                {navigation.map((link, index) => (
                  <NavLink key={index} to={link.path} className={({ isActive }) => (isActive ? `active ${isBlack ? "navbar-links-item-black" : "navbar-links-item"}` : `inactive ${isBlack ? "navbar-links-item-black" : "navbar-links-item"}`)}>
                    <li>
                      <span>{t(`${link.title}`)}</span>
                    </li>
                  </NavLink>
                ))}
              </ul>
            </nav>

            <div className="row">
              <div className="ln-wrapper">
                <LanguageSelector isBlack={isBlack} />
              </div>
              <div className="ham">
                <div className="hamburger-container">
                  <input ref={checkboxRef} onClick={handleMenuClick} type="checkbox" id="hamburger" />
                  <label for="hamburger" className="hamburger">
                    <span className={`${isBlack ? "line-black" : "line"}`}></span>
                    <span className={`${isBlack ? "line-black" : "line"}`}></span>
                    <span className={`${isBlack ? "line-black" : "line"}`}></span>
                  </label>
                </div>
              </div>
            </div>

            <React.Fragment>
              <div id="links-container" className={`links-container${isBlack ? "-black" : ""}${isMenuOpen ? " show" : ""}`}>
                <nav className="navigation-inner">
                  {navigation.map((link, index) => (
                    <NavLink
                      onClick={() => {
                        setIsMenuOpen(false);
                        console.log("ref value", checkboxRef.current.checked);
                        checkboxRef.current.checked = !checkboxRef.current.checked;
                      }}
                      key={index}
                      to={link.path}
                      className={({ isActive }) => (isActive ? `active ${isBlack ? "dr-links-item-black" : "dr-links-item"}` : `inactive ${isBlack ? "dr-links-item-black" : "dr-links-item"}`)}
                    >
                      <li>
                        <span>{t(`${link.title}`)}</span>
                      </li>
                    </NavLink>
                  ))}
                  <LanguageSelector isBlack={isBlack} />
                </nav>
              </div>
            </React.Fragment>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Navbar;
