import React from "react";
import values from "../../imgs/blurs/values.png";
import { Box, Typography, Grid } from "@mui/material";
import item1 from "../../imgs/blurs/item1.jpg";
import item2 from "../../imgs/blurs/item2.jpg";
import item3 from "../../imgs/blurs/item3.jpg";
import item4 from "../../imgs/blurs/item4.jpg";
import "../../utils/gradientText.scss";
import { useTranslation } from "react-i18next";

function ValuesSection() {
  const { t } = useTranslation();

  return (
    <>
      <Box component="section" sx={{ display: "felx", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
        <Box sx={{ position: "relative", padding: "1rem" }}>
          <img style={{ width: "100%", height: "auto" }} src={values} alt="" />
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Typography variant="h3" fontFamily={"ITC"} color="white">
              {t("agencyPage.values.title")}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Grid container className="values" spacing={3}>
            <Grid item md={6}>
              <Box maxWidth="100%" sx={{ padding: "3rem 2rem 0rem 2rem", position: "relative", display: "flex", alignItems: "center", flexDirection: "column", gap: "1rem" }}>
                <Box>
                  <img style={{ width: "100%", height: "auto", opacity: "0.8", zIndex: "-99", position: "absolute", top: "-80%", left: "-50%" }} src={item1} alt="" />
                </Box>
                <Box>
                  <Typography className="gradient-text" variant="h2" sx={{ zIndex: 99 }}>
                    {t("agencyPage.values.value1.name")}
                  </Typography>
                  <Typography variant="h6" fontFamily={"ITC"} textAlign="justify">
                    {t("agencyPage.values.value1.paragraph")}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6} />
            <Grid item md={6} />

            <Grid item md={6}>
              <Box maxWidth="100%" sx={{ padding: "3rem 2rem 0rem 2rem", position: "relative", display: "flex", justifyContent: "center", flexDirection: "column", gap: "1rem" }}>
                <Box>
                  <img style={{ width: "100%", height: "auto", opacity: "0.8", zIndex: "-99", position: "absolute", top: "-50%", left: "50%" }} src={item2} alt="" />
                </Box>

                <Box>
                  <Typography className="gradient-text" variant="h2" sx={{ zIndex: 99 }}>
                    {t("agencyPage.values.value2.name")}
                  </Typography>
                  <Typography variant="h6" fontFamily={"ITC"} textAlign="justify">
                    {t("agencyPage.values.value2.paragraph")}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box maxWidth="100%" sx={{ padding: "3rem 2rem 0rem 2rem", position: "relative", display: "flex", justifyContent: "center", flexDirection: "column", gap: "1rem" }}>
                <Box>
                  <img style={{ width: "100%", height: "auto", opacity: "0.8", zIndex: "-99", position: "absolute", top: "-50%", left: "-50%" }} src={item3} alt="" />
                </Box>

                <Box>
                  <Typography className="gradient-text" variant="h2" sx={{ zIndex: 99 }}>
                    {t("agencyPage.values.value3.name")}
                  </Typography>
                  <Typography variant="h6" fontFamily={"ITC"} textAlign="justify">
                    {t("agencyPage.values.value3.paragraph")}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6} />
            <Grid item md={6} />

            <Grid item md={6}>
              <Box maxWidth="100%" sx={{ padding: "3rem 2rem 0rem 2rem", position: "relative", display: "flex", justifyContent: "center", flexDirection: "column", gap: "1rem" }}>
                <Box>
                  <img style={{ width: "100%", height: "auto", opacity: "0.8", zIndex: "-99", position: "absolute", top: "-40%", left: "50%", overflow: "hidden" }} src={item4} alt="" />
                </Box>

                <Box>
                  <Typography className="gradient-text" variant="h2" sx={{ zIndex: 99 }}>
                    {t("agencyPage.values.value4.name")}
                  </Typography>
                  <Typography variant="h6" fontFamily={"ITC"} textAlign="justify">
                    {t("agencyPage.values.value4.paragraph")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ValuesSection;
