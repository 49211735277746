import React from "react";
import ServicesSection from "./ServicesSection";
import PageContainer from "../../components/ui/PageContainer";
import Footer from "../../components/ui/Footer";

function ServicesPage() {
  return (
    <>
      <ServicesSection />
      <Footer />
    </>
  );
}

export default ServicesPage;
