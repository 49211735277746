import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import teamPhoto from "../../imgs/team.jpg";
function TeamSection() {
  const { t } = useTranslation();

  return (
    <>
      <Box component="section" sx={{ padding: "0rem 0rem 2rem 0rem" }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center", gap: "3rem" }}>
          <Box sx={{ maxWidth: "600px", flex: { md: "1.5" } }}>
            <img style={{ width: "100%", height: "auto", borderRadius: "10px", aspectRatio: "3/3", objectFit: "cover" }} src={teamPhoto}></img>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", maxWidth: "600px", flex: { md: "1" } }}>
            <Box>
              <Typography variant="h2" fontFamily={"ITC"}>
                {t("landingPage.team.title")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1.5rem" }} variant="h6" fontFamily={"ITC"}>
                {t("landingPage.team.paragraph1")}
              </Typography>
              <Typography variant="h6" fontFamily={"ITC"}>
                {t("landingPage.team.paragraph2")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TeamSection;
