import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter } from "react-router-dom";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["fr", "en"],
    backend: { loadPath: "/assets/locales/{{lng}}/global.json" },
    fallbackLng: "fr",
    detection: {
      order: ["localStorage", "path", "subdomain"],
      caches: ["localStorage"],
    },
    react: { useSuspense: true },
  });

let theme = createTheme();
theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </BrowserRouter>
);
