import React from "react";
import ContactSection from "./ContactSection";
import FindUsSection from "./FindUsSection";
import Footer from "../../components/ui/Footer";
import ActionSection from "../Home/ActionSection";
import PageContainer from "../../components/ui/PageContainer";

function ContactPage() {
  return (
    <>
      <ContactSection />

      <FindUsSection />

      <Footer />
    </>
  );
}

export default ContactPage;
