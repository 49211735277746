import React from "react";
import Navbar from "../../components/ui/Navbar";
import { Outlet, useMatch } from "react-router-dom";
import { useState, useEffect } from "react";
import PageContainer from "../../components/ui/PageContainer";
import "./sharedLayoutStyle.scss";
import { useLocation } from "react-router-dom";

function SharedLayout() {
  const [isBlack, setIsBlack] = useState(false);
  const matchServices = useMatch("/services");
  const matchTechnologies = useMatch("/technologies");
  const matchContact = useMatch("/contact");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (matchServices || matchTechnologies || matchContact) {
      setIsBlack(true);
    } else {
      setIsBlack(false);
    }
  }, [window.location.pathname]);

  return (
    <div className={`${isBlack ? "bg-black" : "bg-white"}`}>
      <Navbar isBlack={isBlack} />
      <Outlet />
    </div>
  );
}

export default SharedLayout;
