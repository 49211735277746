import React, { useState } from "react";
import moonz1 from "../../imgs/moonz1.jpg";
import tamkeen from "../../imgs/tamkeen.jpg";
import bdigitall from "../../imgs/bdigitall.jpg";
import dalkm from "../../imgs/dalkm.jpg";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function ProjectsSection() {
  const { t } = useTranslation();

  return (
    <>
      <Box component="section" sx={{ padding: "3rem 0rem" }}>
        <Box component="header" sx={{ marginBottom: "3rem" }}>
          <Typography variant="h2" fontFamily={"ITC"}>
            {t("landingPage.projects.title")}
          </Typography>
        </Box>

        <Box>
          <Grid container sx={{ widht: "100%" }} spacing={6}>
            <Grid item xs={12} md={6}>
              <Box sx={{ maxWidth: "650px", margin: "auto", position: "relative" }}>
                <Box sx={{ padding: "2rem", position: "absolute", bottom: "0.7%", left: "0", width: "100%", background: "linear-gradient(to top, rgba(30, 46, 89, 1), rgba(0, 0, 0, 0))", borderRadius: "0.7rem" }}>
                  <Typography color="white" fontFamily={"ITC"} variant="h4" sx={{ cursor: "default" }}>
                    Dalkia
                  </Typography>
                </Box>
                <img style={{ width: "100%", height: "auto", objectFit: "cover", objectPosition: "center", borderRadius: "0.7rem", aspectRatio: "4/3" }} src={dalkm}></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ maxWidth: "650px", margin: "auto", position: "relative" }}>
                <Box sx={{ padding: "2rem", position: "absolute", bottom: "0.7%", left: "0", width: "100%", background: "linear-gradient(to top, rgba(30, 46, 89, 1), rgba(0, 0, 0, 0))", borderRadius: "0.7rem" }}>
                  <Typography color="white" fontFamily={"ITC"} variant="h4" sx={{ cursor: "default" }}>
                    Moonz App
                  </Typography>
                </Box>
                <img style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", borderRadius: "0.7rem", aspectRatio: "4/3" }} src={moonz1}></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ maxWidth: "650px", margin: "auto", position: "relative" }}>
                <Box sx={{ padding: "2rem", position: "absolute", bottom: "0.7%", left: "0", width: "100%", background: "linear-gradient(to top, rgba(30, 46, 89, 1), rgba(0, 0, 0, 0))", borderRadius: "0.7rem" }}>
                  <Typography color="white" fontFamily={"ITC"} variant="h4" sx={{ cursor: "default" }}>
                    BDigitAll
                  </Typography>
                </Box>
                <img style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", borderRadius: "0.7rem", aspectRatio: "4/3" }} src={bdigitall}></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ maxWidth: "650px", margin: "auto", position: "relative" }}>
                <Box sx={{ padding: "2rem", position: "absolute", bottom: "0.7%", left: "0", width: "100%", background: "linear-gradient(to top, rgba(30, 46, 89, 1), rgba(0, 0, 0, 0))", borderRadius: "0.7rem" }}>
                  <Typography color="white" fontFamily={"ITC"} variant="h4" sx={{ cursor: "default" }}>
                    Tamkeen International
                  </Typography>
                </Box>
                <img style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", borderRadius: "0.7rem", aspectRatio: "4/3" }} src={tamkeen}></img>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ProjectsSection;
