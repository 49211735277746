import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BlogCard from "../../components/ui/BlogCard";
import GradientButton from "../../components/ui/GradientButton";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function BlogSection() {
  const navigate = useNavigate();

  //state tags
  const [tags, setTags] = React.useState([]);
  //state articles
  const [articles, setArticles] = React.useState([]);

  //language
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  console.log("CURRENT LANG", currentLanguage);

  //get list articles
  useEffect(() => {
    Api.get(Urls.GET_ARTICLES, {
      headers: { "Content-Type": "application/json", "accept-language": `${currentLanguage}` },
      params: { en_vedette: 1 },
    })
      .then((response) => {
        console.log(response);
        setArticles(response.data.posts);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [currentLanguage]);

  //handle navigate blog
  const handleNavigateBlog = (article_id) => {
    navigate(`/blog/${article_id}`);
  };

  return (
    <>
      {articles.length > 0 && (
        <Box component="section">
          <Box component="header" sx={{ marginBottom: "3rem" }}>
            <Typography variant="h2" fontFamily={"ITC"}>
              {t("landingPage.blog.title")}
            </Typography>
          </Box>

          <Grid container spacing={1}>
            {articles.map((a, index) => (
              <Grid key={index} item md={4}>
                <BlogCard blogTitle={a.title} blogTags={a.tags} blogPicture={a.image_url} navigateMethod={() => handleNavigateBlog(a.id)} />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <GradientButton>
              <Typography variant="body1" onClick={() => navigate("/blog")}>
                {t("landingPage.blog.button")}
              </Typography>
            </GradientButton>
          </Box>
        </Box>
      )}
    </>
  );
}

export default BlogSection;
