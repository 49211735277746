import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import diamond from "../../imgs/diamond.png";
import "../../utils/gradientText.scss";

import { useTranslation } from "react-i18next";

function HeroSection() {
  const { t } = useTranslation();

  return (
    <>
      <Box component="section" sx={{ padding: "3rem 0rem" }}>
        {/* hero */}
        <Box sx={{ marginBottom: "3rem", display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between" }}>
          {/* header */}

          <Box component="header" sx={{ display: "flex", flexDirection: "column", cursor: "default" }}>
            <Typography variant="h1" fontFamily={"ITC"}>
              {t("landingPage.hero.digitalSolutions")}
            </Typography>

            <Typography variant="h1" fontFamily={"ITC"}>
              {t("landingPage.hero.thatMakeThe")}
            </Typography>

            <Typography className="gradient-text" variant="h1" fontFamily={"ITC"}>
              {t("landingPage.hero.difference")}
            </Typography>

            {/* separator */}
            <Box className="header-title-separator" sx={{ maxWidth: { xs: "4rem", sm: "5.5rem", md: "6.8rem", lg: "10rem", xl: "12.5rem" }, height: { xs: "6px", sm: "7px", md: "8px", lg: "10px", xl: "13px" } }} />
          </Box>

          {/* diamond */}
          <Box sx={{ padding: "0rem 2rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Avatar src={diamond} alt="" variant="square" sx={{ width: { xs: "10rem", sm: "13rem", md: "15rem", lg: "17rem", xl: "21rem" }, height: { xs: "10rem", sm: "13rem", md: "15rem", lg: "17rem", xl: "21rem" } }}></Avatar>
          </Box>
        </Box>
        <Box sx={{ maxWidth: "750px" }}>
          <Typography variant="h5" component="p" fontFamily={"ITC"}>
            {t("landingPage.hero.paragraph")}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default HeroSection;
