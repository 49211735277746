import React from "react";
import { useState } from "react";
import PageContainer from "../../components/ui/PageContainer";
import { Box, Typography, Grid, Container } from "@mui/material";
import MiniTitle from "../../components/ui/MiniTitle.js";
import blurTechnologies2 from "../../imgs/blurs/blurTechnologies2.jpg";
import { styled } from "@mui/system";

import dotnet from "../../imgs/technologies/dotnet.png";
import figma from "../../imgs/technologies/figma.png";
import flutter from "../../imgs/technologies/Flutt.png";
import lara from "../../imgs/technologies/lara.png";
import react_technology from "../../imgs/technologies/react.png";
import spring from "../../imgs/technologies/spring.png";
import wp from "../../imgs/technologies/wp.png";
import symph from "../../imgs/technologies/symphony.png";
import rnative from "../../imgs/technologies/rnative.png";
import drupal from "../../imgs/technologies/drupal.png";
import docker from "../../imgs/technologies/docker.png";
import linux from "../../imgs/technologies/linux.png";
import vue from "../../imgs/technologies/vue.png";
import android from "../../imgs/technologies/android.png";
import pusher from "../../imgs/technologies/pusher.png";
import firebase from "../../imgs/technologies/firebase.png";
import { useTranslation } from "react-i18next";

// flip content

// const GlassBox = styled(Box)({
//   background: "rgba(50, 50, 50, 0.5)",
//   backdropFilter: "blur(10px)",
//   borderRadius: "25px",
//   padding: "20px",
//   border: "1px solid rgb(82, 82, 82)",
// });

// const Front = styled(Box)({
//   position: "absolute",
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backfaceVisibility: "hidden",
//   transform: "rotateY(0deg)",
// });

// const Back = styled(Box)({
//   position: "absolute",
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backfaceVisibility: "hidden",
//   transform: "rotateY(180deg)",
// });

// const FlipBox = styled(Box)({
//   position: "relative",
//   width: "100%",
//   height: "100%",
//   transition: "transform 0.6s",
//   transformStyle: "preserve-3d",
// });

// const GlassBoxItem = ({ title, photo, children, ...props }) => {
//   const [isFlipped, setisFlipped] = useState(false);

//   const handleFlip = () => {
//     setisFlipped(!isFlipped);
//   };

//   return (
//     <GlassBox {...props} onClick={handleFlip}>
//       <FlipBox {...props} onClick={handleFlip} style={{ transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)" }}>
//         <Front>
//           <Box maxWidth="150px">
//             <img width="100%" height="auto" src={photo} alt={title} />
//           </Box>
//         </Front>
//         <Back>
//           <Typography sx={{ maxWidth: "250px" }} variant="h4" color="white" fontFamily={"ITC"}>
//             {title}
//           </Typography>
//         </Back>
//       </FlipBox>
//     </GlassBox>
//   );
// };

//flip whole card
const Front = styled(Box)({
  position: "absolute",
  backfaceVisibility: "hidden",
  transform: "rotateY(0deg)",
});

const Back = styled(Box)({
  position: "absolute",
  backfaceVisibility: "hidden",
  transform: "rotateY(180deg)",
});

const FlipBox = styled(Box)({
  background: "rgba(50, 50, 50, 0.438)",
  // backdropFilter: "blur(10px)",
  borderRadius: "25px",
  // padding: "20px",
  border: "1px solid rgb(82, 82, 82)",
  position: "relative",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d",
});

const GlassBoxItem = ({ title, photo, children, ...props }) => {
  const [isFlipped, setisFlipped] = useState(false);

  const handleFlip = () => {
    setisFlipped(!isFlipped);
  };

  return (
    <Box {...props} onClick={handleFlip} onMouseLeave={handleFlip} onMouseEnter={handleFlip}>
      <FlipBox {...props} onClick={handleFlip} style={{ transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)" }}>
        <Front padding="1rem">
          <Box maxWidth="150px">
            <img width="100%" height="auto" src={photo} alt={title} />
          </Box>
        </Front>
        <Back padding="1rem">
          <Typography sx={{ maxWidth: "250px" }} variant="h4" color="white" fontFamily={"ITC"}>
            {title}
          </Typography>
        </Back>
      </FlipBox>
    </Box>
  );
};

function TechnologiesSection() {
  const technologies_1 = [
    {
      name: "Laravel",
      photo: lara,
    },
    {
      name: "React",
      photo: react_technology,
    },
    {
      name: "WordPress",
      photo: wp,
    },
    {
      name: "Figma",
      photo: figma,
    },
  ];

  const technologies_2 = [
    {
      name: "Flutter",
      photo: flutter,
    },

    {
      name: "Spring",
      photo: spring,
    },
    {
      name: "Android",
      photo: android,
    },
    {
      name: "Pusher",
      photo: pusher,
    },
  ];

  const technologies_3 = [
    {
      name: ".NET",
      photo: dotnet,
    },
    {
      name: "Symfony",
      photo: symph,
    },
    {
      name: "React Native",
      photo: rnative,
    },
    {
      name: "Linux",
      photo: linux,
    },
  ];

  const technologies_4 = [
    {
      name: "Docker",
      photo: docker,
    },
    {
      name: "Drupal",
      photo: drupal,
    },
    {
      name: "Vue.js",
      photo: vue,
    },
    {
      name: "Firebase",
      photo: firebase,
    },
  ];

  const { t } = useTranslation();

  return (
    <>
      <Box component="section" sx={{ position: "relative", zIndex: "0", overflow: "hidden", padding: "3rem 0rem" }}>
        <img src={blurTechnologies2} style={{ zIndex: "0", position: "absolute", top: "0" }} />
        <Container maxWidth="xl">
          <Box sx={{ zIndex: "99", display: "flex", flexDirection: "column" }}>
            <MiniTitle name="Technologies" />
            <Box sx={{ zIndex: "1", paddingBottom: "4rem" }}>
              <Typography color="white" component="h1" variant="h1" fontFamily={"ITC"}>
                {t("technologiesPage.innovative")}
              </Typography>
              <Typography color="white" component="h1" variant="h1" fontFamily={"ITC"}>
                {t("technologiesPage.enabled")}
              </Typography>
              <Typography color="white" component="h1" variant="h1" fontFamily={"ITC"}>
                {t("technologiesPage.technologies")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", zIndex: "99" }}>
              <Typography maxWidth="750px" color="white" component="h3" variant="h6" fontFamily={"ITC"}>
                {t("technologiesPage.paragraph")}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ paddingTop: "5rem", display: "flex", gap: "2rem", flexDirection: "column" }}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: { xl: "6rem", lg: "4rem", md: "2rem", sm: "0rem", xs: "3rem" }, flexDirection: { xs: "column", sm: "row" } }}>
                {technologies_1.map((t, index) => (
                  <GlassBoxItem key={index} title={t.name} photo={t.photo} sx={{ flex: "1", width: "100%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem", cursor: "pointer" }} />
                ))}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: { xl: "6rem", lg: "4rem", md: "2rem", sm: "0rem", xs: "3rem" }, flexDirection: { xs: "column", sm: "row" } }}>
                {technologies_2.map((t, index) => (
                  <GlassBoxItem key={index} title={t.name} photo={t.photo} sx={{ flex: "1", width: "100%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem", cursor: "pointer" }} />
                ))}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: { xl: "6rem", lg: "4rem", md: "2rem", sm: "0rem", xs: "3rem" }, flexDirection: { xs: "column", sm: "row" } }}>
                {technologies_3.map((t, index) => (
                  <GlassBoxItem key={index} title={t.name} photo={t.photo} sx={{ flex: "1", width: "100%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem", cursor: "pointer" }} />
                ))}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: { xl: "6rem", lg: "4rem", md: "2rem", sm: "0rem", xs: "3rem" }, flexDirection: { xs: "column", sm: "row" } }}>
                {technologies_4.map((t, index) => (
                  <GlassBoxItem key={index} title={t.name} photo={t.photo} sx={{ flex: "1", width: "100%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem", cursor: "pointer" }} />
                ))}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default TechnologiesSection;
