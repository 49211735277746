import React from "react";
import { Button } from "@mui/material";
import "./style.scss";

function GradientButton({ children, ...props }) {
  return (
    <>
      <Button sx={{ borderRadius: "5rem", padding: "12px 14px", color: "white" }} className="gradient-btn" disableRipple {...props}>
        {children}
      </Button>
    </>
  );
}

export default GradientButton;
