import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageContainer from "../../components/ui/PageContainer";
import blur from "../../imgs/blurs/blurClients.jpg";
import bdigitall from "../../imgs/clients/bdigitall.png";
import dalk from "../../imgs/clients/dalk.png";
import foundation from "../../imgs/clients/foundation.png";
import hana from "../../imgs/clients/hana.png";
import hec from "../../imgs/clients/hec.png";
import tmk from "../../imgs/clients/tmk.png";

function ProjectsSection() {
  const { t } = useTranslation();
  return (
    <>
      <Box component="section" sx={{ padding: "4rem 0rem" }}>
        <Box sx={{ overflow: "hidden", zIndex: "-2", position: "relative", backgroundColor: "black", padding: "3rem 0rem", marginBottom: "3rem" }}>
          <img src={blur} style={{ zIndex: "-1", position: "absolute", right: "0%", top: "-7%" }} />
          <Container maxWidth="xl">
            <Box sx={{ zIndex: "9999999", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", padding: { xl: "5rem 10rem", lg: "5rem 10rem", md: "5rem 7rem", sm: "5rem 4rem", xs: "5rem 2rem" } }}>
              <Typography color="white" variant="h3" fontFamily={"ITC"}>
                {t("landingPage.clients.title")}
              </Typography>
            </Box>

            <Box>
              <Grid container sx={{ widht: "100%", padding: "3rem" }} spacing={2}>
                <Grid item xs={4} md={2}>
                  <Box sx={{ maxWidth: "200px", minWidth: "90px", margin: "auto" }}>
                    <img style={{ width: "100%", height: "auto" }} src={tmk}></img>
                  </Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Box sx={{ maxWidth: "200px", minWidth: "90px", margin: "auto" }}>
                    <img style={{ width: "100%", height: "auto" }} src={foundation}></img>
                  </Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Box sx={{ maxWidth: "200px", minWidth: "90px", margin: "auto" }}>
                    <img style={{ width: "100%", height: "auto" }} src={bdigitall}></img>
                  </Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Box sx={{ maxWidth: "200px", minWidth: "90px", margin: "auto" }}>
                    <img style={{ width: "100%", height: "auto" }} src={dalk}></img>
                  </Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Box sx={{ maxWidth: "200px", minWidth: "90px", margin: "auto" }}>
                    <img style={{ width: "100%", height: "auto" }} src={hec}></img>
                  </Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Box sx={{ maxWidth: "200px", minWidth: "90px", margin: "auto" }}>
                    <img style={{ width: "100%", height: "auto" }} src={hana}></img>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default ProjectsSection;
