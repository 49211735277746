import React, { useState } from "react";
import "./style.scss";
import i18next from "i18next";
import globe from "../../../imgs/globe.svg";
import globeWhite from "../../../imgs/globe_white.svg";

function LanguageSelector({ isBlack }) {
  // get current language
  const [currentLangCode, setCurrentLangCode] = useState(localStorage.getItem("i18nextLng"));

  // console.log("first get", currentLangCode);

  const handleChangeLangFr = () => {
    i18next.changeLanguage("fr");
    setCurrentLangCode("fr");
  };

  const handleChangeLangEn = () => {
    i18next.changeLanguage("en");
    setCurrentLangCode("en");
  };

  return (
    <>
      <div className="language-selector">
        <ul className="language-selector-wrapper">
          <div className="globe">
            <img className="globe-icon" src={isBlack ? globeWhite : globe} alt="globe icon" />
          </div>
          <li onClick={handleChangeLangFr} className={`${isBlack ? "language-selector-item-black" : "language-selector-item"}  ${currentLangCode === "fr" ? (isBlack ? "language-selector--selected-black" : "language-selector--selected") : ""}`}>
            FR
          </li>
          <span className={`${isBlack ? "vs-black" : "vs"}`} />
          <li onClick={handleChangeLangEn} className={`${isBlack ? "language-selector-item-black" : "language-selector-item"} ${currentLangCode === "en" ? (isBlack ? "language-selector--selected-black" : "language-selector--selected") : ""}`}>
            EN
          </li>
        </ul>
      </div>
    </>
  );
}

export default LanguageSelector;
