import React from "react";
import { InputLabel, styled } from "@mui/material";

const ErrorText = styled(InputLabel)({
  marginTop: "0.3rem",
  color: "rgb(247, 120, 120)",
  fontSize: "0.875rem",
});

function FormikErrorText({ children }) {
  return <ErrorText>{children}</ErrorText>;
}

export default FormikErrorText;
