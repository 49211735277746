import React from "react";
import { Box, Typography } from "@mui/material";
import BlogTag from "../BlogTag";

function BlogCard({ blogPicture, blogTitle, blogTags, navigateMethod }) {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", flexWrap: "wrap", maxWidth: { xs: "100%", md: "30rem" }, padding: "1rem 0rem" }}>
        <Box onClick={navigateMethod} sx={{ cursor: "pointer", width: "100%", height: "auto" }} mb>
          <img style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", borderRadius: "0.7rem", aspectRatio: "16/11" }} src={blogPicture} alt="" />
        </Box>
        <Box mb>
          {blogTags?.map((bt, index) => (
            <BlogTag key={index} title={bt.name} />
          ))}
        </Box>
        <Box onClick={navigateMethod} sx={{ cursor: "pointer" }}>
          <Typography sx={{ wordBreak: "break-word" }} variant="h6" fontFamily={"ITC"}>
            {blogTitle}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default BlogCard;
