import React from "react";
import { InputLabel, styled } from "@mui/material";

const Label = styled(InputLabel)({
  fontSize: "0.875rem",
  marginBottom: ".5rem",
  color: "black",
});

function FormikLabel({ children, ...props }) {
  return <Label {...props}>{children}</Label>;
}

export default FormikLabel;
